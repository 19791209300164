@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400&display=swap);
/* ------------------------------------------------------------ *\
    Reset
\* ------------------------------------------------------------ */

*,
*:before,
*:after { padding: 0; margin: 0; outline: 0; box-sizing: border-box; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, main { display: block; }

html,
body { min-height: 100%; }

html { tap-highlight-color: rgba(0,0,0,0); }

body { -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; -webkit-text-size-adjust: none; -moz-text-size-adjust: none; text-size-adjust: none; }

img, iframe, video, audio, object { max-width: 100%; }
img, iframe { border: 0 none; }
img { height: auto; display: inline-block; vertical-align: middle; }

b,
strong { font-weight: bold; }

address { font-style: normal; }

svg:not(:root) { overflow: hidden; }

a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="image"],
label[for] { cursor: pointer; }

a[href^="tel"],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] { cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner { padding: 0; border: 0; }

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
a[href^="tel"] { -webkit-appearance: none; appearance: none; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button { display: none; -webkit-appearance: none; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

textarea { overflow: auto; resize: none; -webkit-overflow-scrolling: touch; overflow-scrolling: touch; }

button,
input,
optgroup,
select,
textarea { font-family: inherit; font-size: inherit; color: inherit; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; }

button,
select { text-transform: none; }

table { width: 100%; border-collapse: collapse; border-spacing: 0; }

nav ul,
nav ol { list-style: none outside none; }

/* ------------------------------------------------------------ *\
    General
\* ------------------------------------------------------------ */

ul, ol {
    padding-left: 20px
}

@font-face {
    font-family: 'Gill Sans';
    src: url(/static/media/GillSans-Bold.9c507213.woff);
    font-weight: bold;
    font-style: normal;
}

html {
    font-size: 62.5%;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    min-height: 100vh;
    overflow-x: hidden;
}

body {
    font-size: 1.8rem;
}

#root {
	height: 100%;
}

.underline {
  position: relative;
  padding-bottom: 36px;
  margin-bottom: 3.6rem!important;
}

.underline:after {
  content: '';
  position: absolute;
  width: 60px;
  height: 3px;
  background: #150f10;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.smallUnderline {
  padding-bottom: 2.4rem;
  position: relative;
}

.smallUnderline:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #150f10;
}

.smallUnderlineCenter {
  padding-bottom: 2.4rem;
  position: relative;
}

.smallUnderlineCenter:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #ee6f5c;
}

.fade-exit {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 999;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.fade-exit-active {
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
    opacity: 0;
    transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
    transition: opacity .3s, transform .3s, -webkit-transform .3s;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-done {
  transition: opacity .3s;
  opacity: 1;
}

.fade-enter-done {
  transition: opacity .3s;
  opacity: 1;
}

.largeButton {
  padding: 35px 20px!important;
}

.fullWidth {
  width: 100%;
}

.loadContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------------------------------------ *\
    Range input
\* ------------------------------------------------------------ */

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 0px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0px;
  border: 0px;
}
input[type=range]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #fffff0;
}
input[type="range"]::-moz-range-progress {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: #73c3b9;
  border-radius: 0px;
  border: 0px;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: white;
  border-radius: 0px;
  border: 0px;
}
input[type=range]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
  transform: translateY(0);
}

input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: white;
  border-radius: 0px;
  border: 0px;
}
input[type=range]::-ms-fill-lower {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: #73c3b9;
  border-radius: 0px;
  border: 0px;
}
input[type=range]::-ms-fill-upper {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: white;
  border-radius: 0px;
  border: 0px;
}
input[type=range]::-ms-thumb {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
  transform: translateY(0);
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(.5);
            transform: scale(.5);
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(.5);
            transform: scale(.5);
  }
}

@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-webkit-keyframes show {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes show {
  from {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes showBackwards {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes showBackwards {
  from {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes showDownwards {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes showDownwards {
  from {
    opacity: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

